import React from 'react'
import Galleries from '../../components/galleries/galleries.js'
import Layout from '../../layouts/index.js'

const array = [
  '000528',
  '000588',
  '000661',
  '000678',
  '000682',
  '004163',
  '004283',
]

const images = array.map((image) => {
  return (
    <figure>
      <img
        alt=""
        key={image}
        src={require('../../images/gallery/arctic/' + image + '.jpg')}
      />
    </figure>
  )
})

const Arctic = () => (
  <Layout>
    <section>
      <h1>Arctic</h1>
      {images}
      <Galleries />
    </section>
  </Layout>
)

export default Arctic
